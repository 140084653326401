var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"webhookValidation"},[_c('b-modal',{attrs:{"id":this.$attrs.id,"title":"Company Notification","size":"lg","scrollable":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[(_vm.webhook.sendUrl)?_c('b-col',{staticClass:"text-left"},[_c('b-button',{attrs:{"disabled":_vm.buttonsBusy,"type":"button","variant":"primary"},on:{"click":_vm.testWebhook}},[(_vm.testBusy)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Test notification")])],1)],1):_vm._e(),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.buttonsBusy,"variant":"secondary"},on:{"click":close}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"disabled":_vm.buttonsBusy,"type":"button","variant":"primary"},on:{"click":_vm.submitForm}},[(_vm.formBusy)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Submit")])],1)],1)],1)],1)]}}])},[_c('b-row',[_c('b-col',[_c('b-form-checkbox',{attrs:{"id":"status","switch":"","value":"active","unchecked-value":"inactive"},model:{value:(_vm.webhook.status),callback:function ($$v) {_vm.$set(_vm.webhook, "status", $$v)},expression:"webhook.status"}},[_vm._v(" Active ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Name","state":errors.length > 0 ? false:null},model:{value:(_vm.webhook.name),callback:function ($$v) {_vm.$set(_vm.webhook, "name", $$v)},expression:"webhook.name"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('h5',{staticClass:"card-title"},[_vm._v(" Notification channels ")])])],1),_c('b-card',{staticClass:"channel-card"},[_c('b-card-header',{staticClass:"p-0"},[_c('b-form-checkbox',{staticClass:"bg-white",attrs:{"id":"send-url","name":"send-url","switch":"","state":_vm.channelError ? false:null},on:{"change":function($event){return _vm.calculateChannelError()}},model:{value:(_vm.webhook.sendUrl),callback:function ($$v) {_vm.$set(_vm.webhook, "sendUrl", $$v)},expression:"webhook.sendUrl"}},[_vm._v(" Send to URL ")])],1),_c('b-collapse',{model:{value:(_vm.webhook.sendUrl),callback:function ($$v) {_vm.$set(_vm.webhook, "sendUrl", $$v)},expression:"webhook.sendUrl"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Url","rules":(_vm.webhook.sendUrl ? 'required|' : '') + 'url'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","placeholder":"Url","name":"url","state":errors.length > 0 ? false:null},model:{value:(_vm.webhook.url),callback:function ($$v) {_vm.$set(_vm.webhook, "url", $$v)},expression:"webhook.url"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addHeader}},[_vm._v(" Add Header ")])],1)],1)],1),_vm._l((_vm.webhook.headers),function(header,index){return _c('b-row',{key:index},[_c('b-col',[_c('validation-provider',{attrs:{"name":"Header Type","rules":"required|header_key|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2 headerType",attrs:{"placeholder":"Header Type","state":errors.length > 0 ? false:null},model:{value:(header.key),callback:function ($$v) {_vm.$set(header, "key", $$v)},expression:"header.key"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Header Value","rules":"required|max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2 headerValue",attrs:{"placeholder":"Header Value","state":errors.length > 0 ? false:null},model:{value:(header.value),callback:function ($$v) {_vm.$set(header, "value", $$v)},expression:"header.value"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"headerRemove",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeHeader(index)}}},[_c('feather-icon',{staticClass:"d-inline",attrs:{"icon":"TrashIcon"}})],1)],1)],1)})],2)],1),_c('b-card',{staticClass:"channel-card"},[_c('b-card-header',{staticClass:"p-0"},[_c('b-form-checkbox',{staticClass:"bg-white",attrs:{"id":"send-email","switch":"","state":_vm.channelError ? false:null},on:{"change":function($event){return _vm.calculateChannelError()}},model:{value:(_vm.webhook.sendEmail),callback:function ($$v) {_vm.$set(_vm.webhook, "sendEmail", $$v)},expression:"webhook.sendEmail"}},[_vm._v(" Send to Email ")])],1),_c('b-collapse',{model:{value:(_vm.webhook.sendEmail),callback:function ($$v) {_vm.$set(_vm.webhook, "sendEmail", $$v)},expression:"webhook.sendEmail"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addEmail}},[_vm._v(" Add E-mail ")])],1)],1)],1),_vm._l((_vm.webhook.emails.keys()),function(i){return _c('b-row',{key:i,staticClass:"mb-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":'email'+i,"rules":(_vm.webhook.sendEmail ? 'required|' : '') + 'email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'email'+i,"name":"emails[]","placeholder":"Email address","state":errors.length > 0 ? false:null},model:{value:(_vm.webhook.emails[i]),callback:function ($$v) {_vm.$set(_vm.webhook.emails, i, $$v)},expression:"webhook.emails[i]"}})]}}],null,true)})],1),(_vm.webhook.emails.length > 1)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"emailRemove",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeEmail(i)}}},[_c('feather-icon',{staticClass:"d-inline",attrs:{"icon":"TrashIcon"}})],1)],1):_vm._e()],1)})],2)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('h5',{staticClass:"card-title"},[_vm._v(" Actions to be notified ")])])],1),_c('b-row',{staticClass:"actions"},_vm._l((_vm.hooks),function(group,index1){return _c('b-col',{key:index1},[_c('b-form-group',{attrs:{"label":_vm.capitalizeFirstChar(group.value.replace('_', ' ')),"label-for":"hooks"}},_vm._l((group.text),function(setting,index){return _c('b-form-checkbox',{key:index,staticStyle:{"text-wrap":"nowrap"},attrs:{"id":group.value + '/' + setting.value,"checked":true,"switch":"","state":_vm.actionError ? false:null},on:{"change":function($event){return _vm.calculateActionError()}},model:{value:(_vm.webhook.callbacks[group.value + '/' + setting.value]),callback:function ($$v) {_vm.$set(_vm.webhook.callbacks, group.value + '/' + setting.value, $$v)},expression:"webhook.callbacks[group.value + '/' + setting.value]"}},[_vm._v(" "+_vm._s(setting.text)+" ")])}),1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }