<template>
  <validation-observer ref="webhookValidation">
    <b-modal
      :id="this.$attrs.id"
      title="Company Notification"
      size="lg"
      scrollable
    >
      <b-row>
        <b-col>
          <b-form-checkbox
            id="status"
            v-model="webhook.status"
            switch
            value="active"
            unchecked-value="inactive"
          >
            Active
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required|min:3|max:255"
            >
              <b-form-input
                id="name"
                v-model="webhook.name"
                name="name"
                placeholder="Name"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5 class="card-title">
            Notification channels
          </h5>
        </b-col>
      </b-row>
      <b-card class="channel-card">
        <b-card-header class="p-0">
          <b-form-checkbox
            id="send-url"
            v-model="webhook.sendUrl"
            name="send-url"
            switch
            class="bg-white"
            :state="channelError ? false:null"
            @change="calculateChannelError()"
          >
            Send to URL
          </b-form-checkbox>
        </b-card-header>
        <b-collapse
          v-model="webhook.sendUrl"
        >
          <b-row>
            <b-col>
              <b-form-group
                label-for="url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Url"
                  :rules="(webhook.sendUrl ? 'required|' : '') + 'url'"
                >
                  <b-form-input
                    id="url"
                    v-model="webhook.url"
                    placeholder="Url"
                    name="url"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="addHeader"
                >
                  Add Header
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-for="(header, index) in webhook.headers"
            :key="index"
          >
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Header Type"
                rules="required|header_key|max:255"
              >
                <b-form-input
                  v-model="header.key"
                  placeholder="Header Type"
                  class="mb-2 headerType"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Header Value"
                rules="required|max:1024"
              >
                <b-form-input
                  v-model="header.value"
                  placeholder="Header Value"
                  class="mb-2 headerValue"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="outline-danger"
                size="sm"
                class="headerRemove"
                @click="removeHeader(index)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-card>
      <b-card class="channel-card">
        <b-card-header class="p-0">
          <b-form-checkbox
            id="send-email"
            v-model="webhook.sendEmail"
            switch
            class="bg-white"
            :state="channelError ? false:null"
            @change="calculateChannelError()"
          >
            Send to Email
          </b-form-checkbox>
        </b-card-header>
        <b-collapse v-model="webhook.sendEmail">
          <b-row>
            <b-col>
              <b-form-group>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="addEmail"
                >
                  Add E-mail
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-for="i in webhook.emails.keys()"
            :key="i"
            class="mb-2"
          >
            <b-col>
              <validation-provider
                #default="{ errors }"
                :name="'email'+i"
                :rules="(webhook.sendEmail ? 'required|' : '') + 'email'"
              >
                <b-form-input
                  :id="'email'+i"
                  v-model="webhook.emails[i]"
                  name="emails[]"
                  placeholder="Email address"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-col>
            <b-col
              v-if="webhook.emails.length > 1"
              cols="auto"
            >
              <b-button
                variant="outline-danger"
                size="sm"
                class="emailRemove"
                @click="removeEmail(i)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-card>
      <b-row class="mt-2">
        <b-col>
          <h5 class="card-title">
            Actions to be notified
          </h5>
        </b-col>
      </b-row>
      <b-row class="actions">
        <b-col
          v-for="(group, index1) in hooks"
          :key="index1"
        >
          <b-form-group
            :label="capitalizeFirstChar(group.value.replace('_', ' '))"
            label-for="hooks"
          >
            <b-form-checkbox
              v-for="(setting, index) in group.text"
              :id="group.value + '/' + setting.value"
              :key="index"
              v-model="webhook.callbacks[group.value + '/' + setting.value]"
              :checked="true"
              switch
              :state="actionError ? false:null"
              style="text-wrap: nowrap"
              @change="calculateActionError()"
            >
              {{ setting.text }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-container fluid>
          <b-row>
            <b-col
              v-if="webhook.sendUrl"
              class="text-left"
            >
              <b-button
                :disabled="buttonsBusy"
                type="button"
                variant="primary"
                @click="testWebhook"
              >
                <b-spinner
                  v-if="testBusy"
                  small
                />
                <span v-else>Test notification</span>
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button
                :disabled="buttonsBusy"
                variant="secondary"
                class="mr-2"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                :disabled="buttonsBusy"
                type="button"
                variant="primary"
                @click="submitForm"
              >
                <b-spinner
                  v-if="formBusy"
                  small
                />
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BContainer,
  BSpinner,
  BFormCheckbox,
  BCollapse,
  BCard,
  BCardHeader,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BFormCheckbox,
    BCollapse,
    BSpinner,
    BContainer,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    eventHub: {
      default: () => new Vue(),
    },
    webhook: {
      type: Object,
      default() {
        return {
          status: 'inactive',
          name: '',
          sendUrl: false,
          url: '',
          headers: [],
          sendEmail: false,
          emails: [],
          callbacks: {},
        }
      },
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      actionError: false,
      channelError: false,
      formBusy: false,
      testBusy: false,
      hooks: this.$classifiers().getOptions('webhook_callbacks', false),
    }
  },
  computed: {
    buttonsBusy() {
      return this.formBusy || this.testBusy
    },
  },
  methods: {
    calculateActionError() {
      this.actionError = Object.values(this.webhook.callbacks).filter(val => val === true).length === 0
    },
    calculateChannelError() {
      this.channelError = !this.webhook.sendUrl && !this.webhook.sendEmail
    },
    capitalizeFirstChar(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    addHeader() {
      this.webhook.headers.push({ key: '', value: '' })
    },
    removeHeader(index) {
      this.webhook.headers.splice(index, 1)
    },
    addEmail() {
      this.webhook.emails.push('')
    },
    removeEmail(index) {
      this.webhook.emails.splice(index, 1)
    },
    testWebhook() {
      this.$refs.webhookValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.testBusy = true

        const headers = this.webhook.headers.reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value
          }
          return acc
        }, {})
        this.$http.post(`/v1/companies/${this.companyId}/webhooks/test`, this.webhook, { headers })
          .then(response => {
            const { status, data } = response
            const parsedHeaders = response.headers
            const formattedHeaders = Object.entries(parsedHeaders)
              .map(([key, value]) => `${key}: ${value}`)
              .join('<br>')
            const formattedData = JSON.stringify(data, null, 2)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Test Webhook sent successfully',
                text: `
                  <strong>Response Status:</strong> ${status}<br>
                  <strong>Response Content:</strong><br>
                  <pre style="white-space: pre-wrap; word-break: break-word;">${formattedData}</pre>
                  <br><strong>Headers:</strong><br>
                  ${formattedHeaders}`,
                icon: 'CheckIcon',
                variant: 'success',
                html: true,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Test Webhook failed - ${error.response.status}`,
                text: error.response ? error.response.data.message : 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.testBusy = false
          })
      })
    },
    submitForm() {
      if (!this.webhook.sendUrl) { this.webhook.url = '' }
      if (!this.webhook.sendEmail) { this.webhook.emails = [] }

      this.$refs.webhookValidation.validate().then(success => {
        const params = { ...this.webhook }
        params.callbacks = []
        Object.keys(this.webhook.callbacks).forEach(key => {
          if (this.webhook.callbacks[key] === true) {
            params.callbacks.push(key)
          }
        })
        this.calculateActionError()
        if (this.actionError) {
          success = false
        }
        this.calculateChannelError()
        if (this.channelError) {
          success = false
        }

        if (!success) {
          if (!this.webhook.emails.length) {
            this.addEmail()
          }
          return
        }

        this.formBusy = true

        if (this.webhook.id) {
          this.$http.put(`/v1/companies/${this.companyId}/webhooks/${this.webhook.id}`, params)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification edited',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('webhook-modal')
              this.eventHub.$emit('refreshWebhooks')
            })
            .catch(error => {
              handleError(error, this.$toast, 'Error editing notification')
            })
            .finally(() => {
              this.formBusy = false
            })
        } else {
          this.$http.post(`/v1/companies/${this.companyId}/webhooks`, params)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification added',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('webhook-modal')
              this.eventHub.$emit('refreshWebhooks')
            })
            .catch(error => {
              handleError(error, this.$toast, 'Error adding notification')
            })
            .finally(() => {
              this.formBusy = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.channel-card {
  box-shadow: none;
  margin: 0;
  .card-body {
    padding: 0;
  }
}
@media (min-width: (map-get($grid-breakpoints, lg))) {
  .actions {
    display: flex;
    flex-wrap: nowrap;
  }
  .actions .col {
    flex: 1 1 auto;
    min-width: 0;
    max-width: calc(33.33% - 1rem);
  }
}
</style>
