<template>
  <!-- Tab: Contract info -->
  <b-tab>
    <template #title>
      <b-badge
        v-if="tabErrors.details > 0"
        class="mr-1"
        pill
        variant="danger"
      >
        {{ tabErrors.details }}
      </b-badge>
      <feather-icon
        icon="FileTextIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Contracts</span>
    </template>
    <ContractModal
      id="company-contract-modal"
      :contract="contract"
      :event-hub="eventHub"
      :company-id="companyId"
      :tags-list="tags"
    />
    <b-row>
      <b-col class="text-right">
        <b-button
          v-b-modal.company-contract-modal
          :disabled="!$permissions().hasPermission('edit contracts')"
          variant="outline-primary"
          @click="setNewContract"
          class="m-1"
        >
          Add contract
        </b-button>
      </b-col>
    </b-row>
    <contracts-table
      :event-hub="eventHub"
      :company-id="companyId"
    />
  </b-tab>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BButton,
} from 'bootstrap-vue'
import Vue from 'vue'
import ContractsTable from '@/views/company/components/contracts/ContractsTable.vue'
import ContractModal from '@/views/company/components/contracts/ContractModal.vue'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    ContractsTable,
    ContractModal,
  },
  props: ['tabErrors', 'companyId'],
  data() {
    return {
      eventHub: new Vue(),
      contract: {},
      tags: [],
    }
  },
  created() {
    this.eventHub.$on('setContract', this.handleSetContract)
    this.getTagsList()
  },
  beforeDestroy() {
    this.eventHub.$off('setContract', this.handleSetContract)
  },
  methods: {
    handleSetContract(contract) {
      this.contract = contract
    },
    getTagsList() {
      this.$http.get(`/v1/companies/${this.companyId}/contracts/tags`)
        .then(response => {
          this.tags = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    setNewContract() {
      this.contract = {
        id: null,
        valid_from: null,
        valid_to: null,
        company_id: this.$activeCompany().data.company.id,
        configuration: {},
        tags: [],
      }
    },
  },
}
</script>
