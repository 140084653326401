<template>
  <b-table
    id="contracts-table"
    :items="contracts"
    :fields="fields"
    :busy="loading"
    responsive
    stacked="sm"
    class="mb-0"
    show-empty
  >
    <template #cell(status)="data">
      <b-badge
        :variant="data.item.status === 'active' ? 'success' : 'secondary'"
        pill
      >
        {{ data.item.status }}
      </b-badge>
    </template>

    <template #cell(tags)="data">
      <b-badge
        v-for="(tag, index) in data.item.tags"
        :key="index"
        variant="info"
        pill
      >
        {{ tag.value }}
      </b-badge>
    </template>

    <template #cell(created_at)="data">
      {{ data.item.created_at | formatDate }}
    </template>

    <template #cell(valid_from)="data">
      {{ data.item.valid_from | formatDate }}
    </template>

    <template #cell(valid_to)="data">
      <span v-if="data.item.valid_to">{{ data.item.valid_to | formatDate }}</span>
      <span v-else>∞</span>
    </template>

    <template #empty>
      <div class="text-center my-2">
        <p>Company has no contracts</p>
      </div>
    </template>

    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle mr-1" />
        <strong>Loading...</strong>
      </div>
    </template>

    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          variant="primary"
          size="sm"
          :disabled="!$permissions().hasPermission('edit contracts')"
          @click="editContract(data.item)"
        >
          <feather-icon
            icon="EditIcon"
            class="d-inline"
          />
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deleteContract(data.item.id)"
        >
          <feather-icon
            icon="TrashIcon"
            class="d-inline"
          />
        </b-button>
      </b-button-group>
    </template>
  </b-table>
</template>

<script>
import {
  BButton,
  BTable,
  BButtonGroup,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BBadge,
    BSpinner,
    BButtonGroup,
    BButton,
    BTable,
  },
  props: ['eventHub', 'companyId'],
  data() {
    return {
      loading: false,
      deleteBusy: false,
      contracts: [],
      fields: [
        { key: 'status', label: 'Status', sortable: false },
        { key: 'tags', label: 'Tags', sortable: false },
        { key: 'created_at', label: 'Created at', sortable: false },
        { key: 'valid_from', label: 'Valid from', sortable: false },
        { key: 'valid_to', label: 'Valid to', sortable: false },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  created() {
    this.fetchContracts()
    this.eventHub.$on('refreshContracts', this.fetchContracts)
  },
  beforeDestroy() {
    this.eventHub.$off('refreshContracts', this.fetchContracts)
  },
  methods: {
    fetchContracts() {
      this.loading = true
      this.$http.get(`/v1/companies/${this.companyId}/contracts`)
        .then(response => {
          this.contracts = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    editContract(contract) {
      this.eventHub.$emit('setContract', contract)
      this.$bvModal.show('company-contract-modal')
    },
    deleteContract(contractId) {
      this.loading = true
      this.$http.delete(`/v1/companies/${this.companyId}/contracts/${contractId}`)
        .then(() => {
          this.loading = false
          this.fetchContracts()
        })
    },
  },
}
</script>
